<template>
  <div class="s-flat-box">
    <div class="s-plr-16">
      <h2>{{$t('createAccount.title')}}</h2>
      <p>{{$t('createAccount.subTitle')}}</p>
      <div>
        <Input
          v-model="fullname"
          class="s-mt-50"
          :label="$t('createAccount.fullname')"
          placeholder="Your Name"
          :error="error.fullname"
        ></Input>
        <Input
          v-model="phone_number"
          class="s-mt-30"
          :label="$t('createAccount.phoneNumber')"
          placeholder="08888888888"
          :error="error.phone_number"
        ></Input>
        <Input
          v-model="email"
          class="s-mt-30"
          label="Email"
          placeholder="johndoe@email.com"
          :error="error.email"
        ></Input>
        <Input
          v-model="password"
          class="s-mt-30"
          :label="$t('createAccount.password')"
          placeholder="*********"
          type="password"
          :error="error.password"
        ></Input>
      </div>
      <div class="s-fullWidth s-mt-30">
        <Button class="s-fullWidth" @click.prevent="onFinish" :isLoading="isLoading"
          >{{$t('createAccount.createAccount')}}</Button
        >
      </div>
    </div>
    <hr class="s-line-primary s-mtb-16" />
    <div class="s-plr-16">
      <div class="s-fullWidth s-mt-30">
        <router-link
          :to="{ name: 'authentication', params: { authType: 'login' } }"
        >
          <Button class="s-fullWidth" color="secondary">{{$t('createAccount.login')}}</Button>
        </router-link>
      </div>
    </div>
    <Modal v-if="showInfoVerification" :title="$t('createAccount.modalWarning')">
      <template #modalContent>
        <p>{{$t('createAccount.modalMsg')}}</p>

        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button @click.prevent="goToLogin"> {{$t('createAccount.modalYes')}} </Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import form from "@/mixins/form";
import { mapActions } from "vuex";
export default {
  mixins: [form],
  data: () => ({
    isLoading: false,
    fullname: '',
    email: '',
    phone_number: '',
    password: '',
    error: {
      email: false,
      phone_number: false,
      password: false,
      fullname: false
    }
  }),
  computed: {
    showInfoVerification() {
      return this.$store.getters["getModal"];
    },
  },
  methods: {
    ...mapActions({
      register: "auth/register",
      setModal: "setModal",
    }),
    async validation(){
      let errorCount = 0
      if(this.fullname.length == 0 ){
        this.error.fullname = true
        errorCount++
      }
      if(this.email.length == 0 ){
        this.error.email = true
        errorCount++
      }
      if(this.phone_number.length == 0 ){
        this.error.phone_number = true
        errorCount++
      }
      if(this.password.length == 0 ){
        this.error.password = true
        errorCount++
      }
      return errorCount
    },
    async onFinish() {
      this.isLoading = true;
      if( await this.validation() == 0){
        const data = {
          name: this.fullname,
          email: this.email,
          phone_number: this.phone_number,
          password: this.password,
        };

        await this.register(data);
        if (this.$store.getters["auth/getStatus"] == 1) {
          this.setModal(true); 
        }
      }
      this.isLoading = false;
    },
    goToLogin() {
      this.setModal(false);
      this.$router.push(this.$translate({ path: "/login" }));
    },
  },
};
</script>
